import { Environment, getCurrentApiEnv } from '../config/Environment';

const AddWebchatZendesk = () => {
  const existingScript = document.getElementById('ze-snippet');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = Environment.baseUrl[getCurrentApiEnv()].webchatZendeskSrc;
    script.id = 'ze-snippet';
    document.body.appendChild(script);
  }
};

const RemoveWebchatZendesk = () => {
  const existingScript: HTMLElement | null =
    document.getElementById('ze-snippet');
  if (existingScript) {
    existingScript.remove();
  }
};

export { AddWebchatZendesk, RemoveWebchatZendesk };
