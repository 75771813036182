import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import PTBR from './locales/pt-br/translation.json';
import ENUS from './locales/en-us/translation.json';

import BUSINESSUNITSPTBR from './locales/pt-br/businessUnits.json';

const resources = {
  'pt-BR': { translation: { ...PTBR, ...BUSINESSUNITSPTBR } },
  'en-US': { translation: ENUS },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'pt-BR',
  fallbackLng: 'pt-BR',
  interpolation: { escapeValue: false },
});

export default i18n;
