import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

// auth
const Logout = React.lazy(() => import('../pages/account/Logout'));

// pages
const ClientFirstAccess = React.lazy(() =>
  import('../pages/client/FirstAccess')
);
const CheckFirstAccess = React.lazy(() =>
  import('../pages/client/CheckFirstAccess')
);
const ErrorPageNotFound = React.lazy(() =>
  import('../pages/error/PageNotFound')
);
const RequestShipping = React.lazy(() =>
  import('../pages/orders/requestShipping')
);
const MyRequests = React.lazy(() => import('../pages/requests'));
const OrderRequest = React.lazy(() => import('../pages/orders/orderRequest'));
const ReverseOrderRequest = React.lazy(() =>
  import('../pages/orders/reverseOrderRequest')
);
const OrderDetails = React.lazy(() => import('../pages/requests/orderDetails'));
const OrderPayment = React.lazy(() => import('../pages/orders/orderPayment'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));
const LabelPage = React.lazy(() =>
  import('../pages/requests/LabelPage/LabelPage')
);

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  name: 'Home',
  component: () => <Redirect to="/client/check-access" />,
  route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
  path: '/dashboard',
  name: 'Dashboards',
  icon: 'uil-home-alt',
  header: 'Navigation',
  children: [
    {
      path: '/request-shipping',
      name: 'RequestShipping',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: RequestShipping,
      route: PrivateRoute,
    },
    {
      path: '/requests',
      exact: true,
      name: 'MyRequests',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: MyRequests,
      route: PrivateRoute,
    },
    {
      path: '/order-request',
      name: 'OrderRequest',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: OrderRequest,
      route: PrivateRoute,
    },
    {
      path: '/reverse-order-request',
      name: 'ReverseOrderRequest',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: ReverseOrderRequest,
      route: PrivateRoute,
    },
    {
      path: '/requests/details/:id',
      name: 'OrderDetails',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: OrderDetails,
      route: PrivateRoute,
    },
    {
      path: '/order-payment/:id',
      name: 'OrderPayment',
      badge: {
        variant: 'success',
        text: '3',
      },
      component: OrderPayment,
      route: PrivateRoute,
    },
  ],
};

const otherPublicRoutes = [
  {
    path: '/error-404',
    name: 'Error - 404',
    component: ErrorPageNotFound,
    route: Route,
  },
  {
    path: '/error-500',
    name: 'Error - 500',
    component: ServerError,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// auth
const authRoutes = [
  {
    path: '/account/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
  },
];

const routesDefaultLayout = [
  {
    path: '/client/registration',
    name: 'Registration',
    component: ClientFirstAccess,
    route: PrivateRoute,
  },
  {
    path: '/client/check-access',
    name: 'Init',
    component: CheckFirstAccess,
    route: PrivateRoute,
  },
];

const defaultLabelRoute = [
  {
    path: '/LabelPage/:tracking_number',
    name: 'LabelPage',
    component: LabelPage,
    route: PrivateRoute,
  },
];

// All routes
const authProtectedRoutes = [dashboardRoutes];
const publicRoutes = [
  rootRoute,
  ...authRoutes,
  ...otherPublicRoutes,
  ...routesDefaultLayout,
  ...defaultLabelRoute,
];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
