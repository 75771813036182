/**
 * Configuração das rotas do APP para comunicação com a API
 */

export const getCurrentApiEnv = () => {
  if (process.env.REACT_APP_ENV === 'local') return 'local';
  if (process.env.REACT_APP_ENV === 'homolog') return 'homolog';
  if (process.env.REACT_APP_ENV === 'production') return 'production';
  if (process.env.REACT_APP_ENV === 'NewDevelop') return 'NewDevelop';
  if (process.env.REACT_APP_ENV === 'NewHomolog') return 'NewHomolog';
  return 'dev';
};

export const Environment = {
  baseUrl: {
    local: {
      urlApi: 'https://app-pudo-clients-api-dev.azurewebsites.net/api/',
      urlCarriers: 'https://app-pudo-carriers-api-dev.azurewebsites.net/api/',
      urlOrders: 'https://app-pudo-orders-api-dev.azurewebsites.net/api/',
      urlPayments: 'https://app-pudo-payments-api-dev.azurewebsites.net/api/',
      urlBusinessUnits:
        'https://app-pudo-businessunits-api-dev.azurewebsites.net/api/',
      funcPostalCode: {
        url: 'https://func-pudo-postalcode-dev.azurewebsites.net/api/',
        code: 'euDmMqf1aZFz4PzV7coboOzrzCZzWtmOPlobkPIpXjSsj0AQMTJarg==',
      },
      functionPagSeguro: '',
      webchatZendeskSrc:
        'https://static.zdassets.com/ekr/snippet.js?key=d7c951e0-1192-4355-b98c-fc39c9bc020c',
      auth0ClientId: 'HKVuaxaGmVxhcQvw47J6EZ4KDdExJVZL',
      auth0Domain: 'pudo-dev.us.auth0.com',
      auth0Audience: 'https://apis/clients-api',
    },
    dev: {
      urlApi: 'https://app-pudo-clients-api-dev.azurewebsites.net/api/',
      urlCarriers: 'https://app-pudo-carriers-api-dev.azurewebsites.net/api/',
      urlOrders: 'https://app-pudo-orders-api-dev.azurewebsites.net/api/',
      urlPayments: 'https://app-pudo-payments-api-dev.azurewebsites.net/api/',
      urlBusinessUnits:
        'https://app-pudo-businessunits-api-dev.azurewebsites.net/api/',
      funcPostalCode: {
        url: 'https://func-pudo-postalcode-dev.azurewebsites.net/api/',
        code: 'euDmMqf1aZFz4PzV7coboOzrzCZzWtmOPlobkPIpXjSsj0AQMTJarg==',
      },
      functionPagSeguro: '',
      webchatZendeskSrc:
        'https://static.zdassets.com/ekr/snippet.js?key=d7c951e0-1192-4355-b98c-fc39c9bc020c',
      auth0ClientId: 'HKVuaxaGmVxhcQvw47J6EZ4KDdExJVZL',
      auth0Domain: 'pudo-dev.us.auth0.com',
      auth0Audience: 'https://apis/clients-api',
    },
    NewDevelop: {
      urlApi: 'https://app-drops-clients-api-dev.azurewebsites.net/api/',
      urlCarriers: 'https://app-drops-carriers-api-dev.azurewebsites.net/api/',
      urlOrders: 'https://app-drops-order-api-dev.azurewebsites.net/api/',
      urlPayments: 'https://app-drops-payment-api-dev.azurewebsites.net/api/',
      urlBusinessUnits:
        'https://app-drops-businessunits-api-dev.azurewebsites.net/api/',
      funcPostalCode: {
        url: 'https://func-drops-postal-code-dev.azurewebsites.net/api/',
        code: 'GTHxoXAz0hS4L2ej/XZfz1GZ4l12IDMfyVDnGAZQQSLbmVnCIct16w==',
      },
      functionPagSeguro: '',
      webchatZendeskSrc:
        'https://static.zdassets.com/ekr/snippet.js?key=d7c951e0-1192-4355-b98c-fc39c9bc020c',
      auth0ClientId: 'HKVuaxaGmVxhcQvw47J6EZ4KDdExJVZL',
      auth0Domain: 'pudo-dev.us.auth0.com',
      auth0Audience: 'https://apis/clients-api',
    },
    homolog: {
      urlApi: 'https://app-pudo-clients-api-hml.azurewebsites.net/api/',
      urlCarriers: 'https://app-pudo-carriers-api-hml.azurewebsites.net/api/',
      urlOrders: 'https://app-pudo-orders-api-hml.azurewebsites.net/api/',
      urlPayments: 'https://app-pudo-payments-api-hml.azurewebsites.net/api/',
      urlBusinessUnits:
        'https://app-pudo-businessunits-api-hml.azurewebsites.net/api/',
      funcPostalCode: {
        url: 'https://func-pudo-postalcode-hml.azurewebsites.net/api/',
        code: 'GTHxoXAz0hS4L2ej/XZfz1GZ4l12IDMfyVDnGAZQQSLbmVnCIct16w==',
      },
      functionPagSeguro: '',
      webchatZendeskSrc:
        'https://static.zdassets.com/ekr/snippet.js?key=d7c951e0-1192-4355-b98c-fc39c9bc020c',
      auth0ClientId: 'jgJb55wePVKGzNQn5gYxYwtkCoz1xJwB',
      auth0Domain: 'login-hml.pontodrops.com.br',
      auth0Audience: 'https://apis/clients-api',
    },
    NewHomolog: {
      urlApi: 'https://app-drops-clients-api-hml.azurewebsites.net/api/',
      urlCarriers: 'https://app-drops-carriers-api-hml.azurewebsites.net/api/',
      urlOrders: 'https://app-drops-order-api-hml.azurewebsites.net/api/',
      urlPayments: 'https://app-drops-payment-api-hml.azurewebsites.net/api/',
      urlBusinessUnits:
        'https://app-drops-businessunits-api-hml.azurewebsites.net/api/',
      funcPostalCode: {
        url: 'https://func-drops-postal-code-hml.azurewebsites.net/api/',
        code: 'GTHxoXAz0hS4L2ej/XZfz1GZ4l12IDMfyVDnGAZQQSLbmVnCIct16w==',
      },
      functionPagSeguro: '',
      webchatZendeskSrc:
        'https://static.zdassets.com/ekr/snippet.js?key=d7c951e0-1192-4355-b98c-fc39c9bc020c',
      auth0ClientId: 'jgJb55wePVKGzNQn5gYxYwtkCoz1xJwB',
      auth0Domain: 'login-hml.pontodrops.com.br',
      auth0Audience: 'https://apis/clients-api',
    },
    production: {
      urlApi: 'https://clients-api.pontodrops.com.br/api/',
      urlCarriers: 'https://carriers-api.pontodrops.com.br/api/',
      urlOrders: 'https://orders-api.pontodrops.com.br/api/',
      urlPayments: 'https://payments-api.pontodrops.com.br/api/',
      urlBusinessUnits: 'https://businessunits-api.pontodrops.com.br/api/',
      funcPostalCode: {
        url: 'https://postalcode.pontodrops.com.br/api/',
        code: 'wik0dMBik0BQX8V39QTUpqRMyzVUd1HcqS29MUWDQkJI1l1AbeVs3w==',
      },
      functionPagSeguro: '',
      webchatZendeskSrc:
        'https://static.zdassets.com/ekr/snippet.js?key=d7c951e0-1192-4355-b98c-fc39c9bc020c',
      auth0ClientId: 'iCyJQDHd7IeCtbUsnxa26N7vf9iN7MeY',
      auth0Domain: 'login.pontodrops.com.br',
      auth0Audience: 'https://apis/clients-api',
    },
  },
};
