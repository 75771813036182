// @flow
import { ReactElement, useEffect } from 'react';
import Routes from './routes/Routes';

import './assets/scss/Saas.scss';
import { AddWebchatZendesk } from './utils/webchatZendesk';

const App = (props: any): ReactElement<any> => {
  const inLabelPage = () => {
    const urlPath = window.location.pathname;
    if (urlPath.includes('LabelPage')) return true;
    return false;
  };

  const webchatZendesk = () => {
    const validUser = localStorage.getItem('validUser');
    if (
      validUser &&
      window.location.pathname !== '/account/logout' &&
      !inLabelPage()
    ) {
      AddWebchatZendesk();
    }
  };

  useEffect(() => {
    webchatZendesk();
  });

  return <Routes />;
};

export default App;
